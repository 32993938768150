import './index.scss'
import logo from '../../assets/icon.png'

export default function SuccessPage() {
  return <div className='success-page'>
    <img src={logo} alt="Business Goals Logo" draggable={false} />

    <h1>🎉 Welcome to <span className='color'>Business Goals</span>!</h1>
    <p>
      Head over to our <a href="https://app.businessgoals.org/">website</a> to start using the online program.
      <br />
      <br />
      Or, download the app from the <a href="https://apps.apple.com/us/app/business-goals/id1475842149">App Store</a>.
    </p>

    <br />
    <br />

    <span>
      You'll sign in with the email and password you just set.
      <br />
      <br />
      If you can't login, check back in a bit. Our payment provider may still be processing your subscription.
    </span>
  </div>
}