import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/icon.png'
import phone from '../../assets/phone.png'
import './index.scss'

export default function LandingPage() {
  const navigate = useNavigate();
  const [showingForm, setShowingForm] = useState(true);

  // Image prewarming
  useEffect(() => {
    [logo, phone].forEach((imageSrc) => {
      const image = new Image();
      image.src = imageSrc;
    })
  })

  return <div className={`landing-page ${showingForm ? '' : 'hidden'}`}>
    <form>
      <img src={logo} alt="Business Goals Logo" draggable={false} />

      <button onClick={(e) => {
        e.preventDefault();

        setShowingForm(false);

        setTimeout(() => {
          navigate("/app")
        }, 300);
      }}>
        <h1>Get started with<br /><span>Business Goals</span> &rarr;</h1>
      </button>
    </form>
  </div>
}