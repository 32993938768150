import { Route, Routes, BrowserRouter } from "react-router-dom";
import AppPage from "./pages/app";
import FormPage from "./pages/form";

import LandingPage from './pages/landing';
import SuccessPage from "./pages/success";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/app" element={<AppPage />} />
        <Route path="/form" element={<FormPage />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

