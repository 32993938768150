import axios, { AxiosError } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import './index.scss'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");

export default function FormPage() {
  const [showingForm, setShowingForm] = useState(false);
  const [payload, setPayload] = useState<{
    email?: string;
    password?: string;
    name?: string;
    organizationName?: string;
  }>({});
  const [error, setError] = useState<string | null>(null);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowingForm(true)
    }, 50);
  });

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;

    setError(null);

    setPayload({
      ...payload,
      [id]: event.target.value
    });
  }

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (submitting) return;

    if (payload.email === undefined) {
      setError("Please enter an email address.");
      return;
    }

    if (payload.password === undefined) {
      setError("Please enter a password.");
      return;
    }

    if (payload.name === undefined) {
      setError("Please enter your name.");
      return;
    }

    if (payload.organizationName === undefined) {
      setError("Please enter your organization's name.");
      return;
    }

    setSubmitting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_URL}/stripe/create-checkout-session`,
        payload
      );
  
      const sessionId = response.data.sessionId;
  
      const stripe = await stripePromise;
  
      stripe?.redirectToCheckout({
        sessionId: sessionId
      });
    } catch (err: any) {
      console.error(err);

      if (err instanceof AxiosError) {
        setError(err.response?.data?.error ?? "Something went wrong. Please try again later.");
      } else {
        setError("Something went wrong. Please try again later.");
      }
    }

    setSubmitting(false);
  }

  return <div className={`form-page ${showingForm ? '' : 'hidden'}`}>
    <form>
      <h3>👋 Welcome to <span>Business Goals</span></h3>
      <p className='subtitle'>Please fill out this form so we can get your account created in our system.</p>

      {error !== null ? <span className='error'>{error}</span> : null}

      <label>Your Organization's Name</label>
      <input type="text" id="organizationName" placeholder={`Acme, Inc.`} onChange={changeHandler} disabled={submitting} />

      <label>Your Name</label>
      <input type="text" id="name" placeholder={`John Appleseed`} onChange={changeHandler} disabled={submitting} />

      <label>Your Email</label>
      <input type="email" id="email" placeholder={`jappleseed@businessgoals.org`} onChange={changeHandler} disabled={submitting} />

      <label>Your Password</label>
      <input type="password" id="password" placeholder={`•••••••••`} onChange={changeHandler} disabled={submitting} />
      <p>This password will be what you use to sign in to Business Goals.</p>

      <button type="submit" onClick={onSubmit} disabled={submitting} className={submitting ? 'disabled' : ''}>
        <h1>Continue to Payment &rarr;</h1>
      </button>
    </form>
  </div>
}