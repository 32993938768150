import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import phone from '../../assets/phone.png'
import './index.scss'

export default function AppPage() {
  const navigate = useNavigate();
  const [showing, setShowing] = useState(false);
  const [showExitAnim, setShowExitAnim] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowing(true)
    }, 50);
  });

  return <div className={`app ${showing ? '' : 'hidden'} ${showExitAnim ? 'hidden-left' : ''}`}>
    <img src={phone} className={`phone`} alt="The Business Goals app, with 3 tasks listed" />

    <div>
      <h2>The App.</h2>
      <p>Seldom are to-do lists tied directly to goals and objectives. The Business Goals app shows us exactly why we are doing what we are doing every day and the level of importance it has. If it's not important…why would you work on it?</p>
      <button className='clear action' onClick={(e) => {
        e.preventDefault();

        setShowExitAnim(true);

        setTimeout(() => {
          navigate("/form")
        }, 500);
      }}>Sign Up &rarr;</button>
    </div>
  </div>
}